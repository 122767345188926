import { Component } from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material';

@Component({
  selector: 'my-app',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.css' ]
})


export class AppComponent  {
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  on: any=20;
  showOn: any=20;
  onValue: any =0;
  showOnValue :any =0;
  totalValue: any=0;
  points: number[] = [];

onKeyUp(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    
    if (value) {
      this.points.push(parseFloat(event.value));
      this.calculate();
    }

    if (input) {
      input.value = '';
    }
}

  remove(index): void {
    if (index >= 0) {
      this.points.splice(index, 1);
      this.calculate();
    }
  }

  calculate(){
    var total = 0;
    
    for (var i = 0; i < this.points.length;i++){
      total += this.points[i];
    }
    
    this.onValue = total;
    this.showOnValue = total * this.showOn / this.on;
    this.totalValue = total * 100 / this.on;
  }

  clear(){
    this.points = [];
    this.onValue = 0;
    this.showOnValue = 0;
    this.totalValue = 0;
  }
}
